import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminAddReminder = () => {
  const [message, setMessage] = useState("");
  const [recipientType, setRecipientType] = useState("email");
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [currentRecipient, setCurrentRecipient] = useState("");
  const [recipients, setRecipients] = useState([]);

  useEffect(() => {
    const fetchRecipients = async () => {
      try {
        let response;
        if (recipientType === "email") {
          response = await axios.get(
            `${process.env.REACT_APP_API_URL}/students/emails`
          );
        } else if (recipientType === "phone" || recipientType === "whatsapp") {
          response = await axios.get(
            `${process.env.REACT_APP_API_URL}/students/phones`
          );
        }
        setRecipients(response.data);
      } catch (error) {
        toast.error("Failed to fetch recipients");
      }
    };

    fetchRecipients();
  }, [recipientType]);

  const handleSendReminder = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/reminders/send-reminder`,

        {
          message,
          recipientType,
          recipients: selectedRecipients,
        }
      );
      toast.success(
        `${
          recipientType.charAt(0).toUpperCase() + recipientType.slice(1)
        } messages sent!`
      );
    } catch (error) {
      toast.error("Failed to send messages");
    }
  };

  const handleAddRecipient = () => {
    if (currentRecipient && !selectedRecipients.includes(currentRecipient)) {
      setSelectedRecipients([...selectedRecipients, currentRecipient]);
      setCurrentRecipient("");
    }
  };

  const handleRecipientTypeChange = (e) => {
    setRecipientType(e.target.value);
    setSelectedRecipients([]);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-blue-400 text-black">
      <div className="w-1/2 p-6 bg-blue-300 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-center">Send Reminder</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Recipient Type
          </label>
          <select
            className="w-full p-2 border border-gray-300 rounded text-black"
            value={recipientType}
            onChange={handleRecipientTypeChange}
          >
            <option value="email">Email</option>
            <option value="phone">Phone</option>
            <option value="whatsapp">WhatsApp</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Recipient
          </label>
          <div className="flex">
            <select
              className="w-full p-2 border border-gray-300 rounded text-black"
              value={currentRecipient}
              onChange={(e) => setCurrentRecipient(e.target.value)}
            >
              <option value="">Select a recipient</option>
              {recipients.map((recipient, index) => (
                <option key={index} value={recipient.email || recipient.phone}>
                  {recipient.email || recipient.phone}
                </option>
              ))}
            </select>
            <button
              onClick={handleAddRecipient}
              className="ml-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              Add
            </button>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Selected Recipients
          </label>
          <div className="border border-gray-300 rounded p-2">
            {selectedRecipients.length > 0 ? (
              selectedRecipients.map((recipient, index) => (
                <div key={index} className="mb-1">
                  {recipient}
                </div>
              ))
            ) : (
              <div>No recipients selected</div>
            )}
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Message
          </label>
          <textarea
            className="w-full p-2 border border-gray-300 rounded"
            rows="4"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="flex space-x-4">
          <button
            onClick={handleSendReminder}
            className={`w-full py-2 px-4 ${
              recipientType === "whatsapp"
                ? "bg-green-500 hover:bg-green-600"
                : "bg-gray-300 cursor-not-allowed"
            } text-white rounded`}
            disabled={recipientType !== "whatsapp"}
          >
            Send WhatsApp
          </button>
          <button
            onClick={handleSendReminder}
            className={`w-full py-2 px-4 ${
              recipientType === "email"
                ? "bg-blue-500 hover:bg-blue-600"
                : "bg-blue-300 cursor-not-allowed"
            } text-white rounded`}
            disabled={recipientType !== "email"}
          >
            Send Email
          </button>
          <button
            onClick={handleSendReminder}
            className={`w-full py-2 px-4 ${
              recipientType === "phone"
                ? "bg-gray-500 hover:bg-gray-600"
                : "bg-gray-300 cursor-not-allowed"
            } text-white rounded`}
            disabled={recipientType !== "phone"}
          >
            Send Text
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminAddReminder;
